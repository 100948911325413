import validate from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import current_45module_45global from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/middleware/currentModule.global.js";
import is_45authenticated_45global from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/middleware/isAuthenticated.global.js";
export const globalMiddleware = [
  validate,
  current_45module_45global,
  is_45authenticated_45global
]
export const namedMiddleware = {
  auth: () => import("/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/middleware/auth.ts"),
  "can-access-route": () => import("/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/middleware/canAccessRoute.js"),
  guest: () => import("/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/middleware/guest.ts"),
  prefetch: () => import("/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/middleware/prefetch.ts"),
  unverified: () => import("/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/middleware/unverified.ts"),
  verified: () => import("/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/middleware/verified.ts")
}