import { default as _91_46_46_46slug_93cBtGSPtiCvMeta } from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/pages/[...slug].vue?macro=true";
import { default as edit5auYv817caMeta } from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/pages/application/edit.vue?macro=true";
import { default as indexks8QbjeNSpMeta } from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/pages/application/index.vue?macro=true";
import { default as indexvQiFZV31GUMeta } from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/pages/auth/index.vue?macro=true";
import { default as indexo1diJ4UtIlMeta } from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/pages/index.vue?macro=true";
import { default as index7jDg5VBbZ5Meta } from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/pages/myjuleica/index.vue?macro=true";
import { default as userQt9dJT5ntaMeta } from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/pages/myjuleica/user.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93cBtGSPtiCvMeta?.name ?? "slug",
    path: _91_46_46_46slug_93cBtGSPtiCvMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93cBtGSPtiCvMeta || {},
    alias: _91_46_46_46slug_93cBtGSPtiCvMeta?.alias || [],
    redirect: _91_46_46_46slug_93cBtGSPtiCvMeta?.redirect || undefined,
    component: () => import("/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: edit5auYv817caMeta?.name ?? "application-edit",
    path: edit5auYv817caMeta?.path ?? "/application/edit",
    meta: edit5auYv817caMeta || {},
    alias: edit5auYv817caMeta?.alias || [],
    redirect: edit5auYv817caMeta?.redirect || undefined,
    component: () => import("/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/pages/application/edit.vue").then(m => m.default || m)
  },
  {
    name: indexks8QbjeNSpMeta?.name ?? "application",
    path: indexks8QbjeNSpMeta?.path ?? "/application",
    meta: indexks8QbjeNSpMeta || {},
    alias: indexks8QbjeNSpMeta?.alias || [],
    redirect: indexks8QbjeNSpMeta?.redirect || undefined,
    component: () => import("/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/pages/application/index.vue").then(m => m.default || m)
  },
  {
    name: indexvQiFZV31GUMeta?.name ?? "auth",
    path: indexvQiFZV31GUMeta?.path ?? "/auth",
    meta: indexvQiFZV31GUMeta || {},
    alias: indexvQiFZV31GUMeta?.alias || [],
    redirect: indexvQiFZV31GUMeta?.redirect || undefined,
    component: () => import("/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexo1diJ4UtIlMeta?.name ?? "index",
    path: indexo1diJ4UtIlMeta?.path ?? "/",
    meta: indexo1diJ4UtIlMeta || {},
    alias: indexo1diJ4UtIlMeta?.alias || [],
    redirect: indexo1diJ4UtIlMeta?.redirect || undefined,
    component: () => import("/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index7jDg5VBbZ5Meta?.name ?? "myjuleica",
    path: index7jDg5VBbZ5Meta?.path ?? "/myjuleica",
    meta: index7jDg5VBbZ5Meta || {},
    alias: index7jDg5VBbZ5Meta?.alias || [],
    redirect: index7jDg5VBbZ5Meta?.redirect || undefined,
    component: () => import("/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/pages/myjuleica/index.vue").then(m => m.default || m)
  },
  {
    name: userQt9dJT5ntaMeta?.name ?? "myjuleica-user",
    path: userQt9dJT5ntaMeta?.path ?? "/myjuleica/user",
    meta: userQt9dJT5ntaMeta || {},
    alias: userQt9dJT5ntaMeta?.alias || [],
    redirect: userQt9dJT5ntaMeta?.redirect || undefined,
    component: () => import("/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/pages/myjuleica/user.vue").then(m => m.default || m)
  }
]