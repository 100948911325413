import revive_payload_client_4sVQNw7RlN from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_jmwsqit4Rs from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5tzzN0oIVL from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import leaflet_runtime_mlkDTJ7gZI from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/node_modules/nuxt3-leaflet/dist/runtime/leaflet-runtime.mjs";
import plugin_8SbxDRbG6Y from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import strapi_CICqBBANNd from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import i18n_yfWm7jX06p from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_vT9JWWT9pN from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/plugins/auth.ts";
import axios_QMFgzss1s4 from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/plugins/axios.ts";
import casl_7eGVIGiOQa from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/plugins/casl.js";
import caslStorePlugin_2XZOpv0jMH from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/plugins/caslStorePlugin.ts";
import chart_3lUUfbbx0f from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/plugins/chart.ts";
import farbcode_core_rz2AqV8wmd from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/plugins/farbcode-core.js";
import fontAwesome_OdbcMzCLm1 from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/plugins/fontAwesome.ts";
import helperFunctions_mNt4iIg5k0 from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/plugins/helperFunctions.ts";
import sweetalert2_client_vmjArNBT49 from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/plugins/sweetalert2.client.ts";
import vee_validate_KcKlKmvCrJ from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/plugins/vee-validate.ts";
import vuex_F8JCgj45hr from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/plugins/vuex.ts";
import ziggy_861J4JMJOx from "/home/forge/juleica-preview.farbcode.net/releases/20250324082942/client-frontend/plugins/ziggy.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  prefetch_client_5tzzN0oIVL,
  leaflet_runtime_mlkDTJ7gZI,
  plugin_8SbxDRbG6Y,
  strapi_CICqBBANNd,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  auth_vT9JWWT9pN,
  axios_QMFgzss1s4,
  casl_7eGVIGiOQa,
  caslStorePlugin_2XZOpv0jMH,
  chart_3lUUfbbx0f,
  farbcode_core_rz2AqV8wmd,
  fontAwesome_OdbcMzCLm1,
  helperFunctions_mNt4iIg5k0,
  sweetalert2_client_vmjArNBT49,
  vee_validate_KcKlKmvCrJ,
  vuex_F8JCgj45hr,
  ziggy_861J4JMJOx
]